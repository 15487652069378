import axios from "axios";

export const sendRequest = ({
  baseURL,
  url,
  method,
  params = {},
  body,
  headers,
}) => {
  const request = {
    baseURL,
    url,
    method,
    params,
    headers,
    data: body,
  };

  return axios(request)
    .then((response) => response.data)
    .catch((e) => {
      // can be optimise in the future
      console.log(e);
      return Promise.resolve({});
    });
};
