import "./gameModal.scss";

const GameModal = ({ gameLink, setGameLink }) => {
  return (
    <div className="gameModal fixed top-0 left-0 w-screen h-screen bg-black z-30 flex sm:flex-col flex-row-reverse">
      <button
        onClick={() => setGameLink("")}
        className="text-white m-6 rounded-full border-2 border-white font-bold text-xl w-12 h-12 relative self-end z-50 flex-shrink-0"
      ></button>
      <div className="iframeWrapper">
        <iframe
          src={gameLink}
          width="100%"
          height="100%"
          frameborder="0"
          title={"Web Game"}
          allow="autoplay"
        ></iframe>
      </div>
    </div>
  );
};

export default GameModal;
