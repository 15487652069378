import React, { useState } from 'react';
import './Modal.css';
 
const Modal =  ({ setShowModal })  => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="close"  onClick={() => setShowModal(false)}></span>
        {/* <p>这里是弹窗内容</p> */}
      </div>
    </div>
  );
};
 
export default Modal;