import Title28g from "../assets/gameList/gameInfo/28g/title.png";
import Titlebg from "../assets/gameList/play_now.png";
import Btn128g from "../assets/gameList/gameInfo/28g/btn1.png";
import Btn228g from "../assets/gameList/gameInfo/28g/btn2.png";
import Intro28g from "../assets/gameList/gameInfo/28g/intro.png";
import Title21d from "../assets/gameList/gameInfo/21d/title.png";
import Btn121d from "../assets/gameList/gameInfo/21d/btn1.png";
import Btn221d from "../assets/gameList/gameInfo/21d/btn2.png";
import Intro21d from "../assets/gameList/gameInfo/21d/intro.png";

import TitleDdz from "../assets/gameList/gameInfo/ddz/title.png";
import Btn1Ddz from "../assets/gameList/gameInfo/ddz/btn1.png";
import Btn2Ddz from "../assets/gameList/gameInfo/ddz/btn2.png";
import IntroDdz from "../assets/gameList/gameInfo/ddz/intro.png";

import TitleDfdc from "../assets/gameList/gameInfo/dfdc/title.png";
import Btn1Dfdc from "../assets/gameList/gameInfo/dfdc/btn1.png";
import Btn2Dfdc from "../assets/gameList/gameInfo/dfdc/btn2.png";
import IntroDfdc from "../assets/gameList/gameInfo/dfdc/intro.png";

import TitleBrnn from "../assets/gameList/gameInfo/brnn/title.png";
import Btn1Brnn from "../assets/gameList/gameInfo/brnn/btn1.png";
import Btn2Brnn from "../assets/gameList/gameInfo/brnn/btn2.png";
import IntroBrnn from "../assets/gameList/gameInfo/brnn/intro.png";

import TitleBjl from "../assets/gameList/gameInfo/bjl/title.png";
import Btn1Bjl from "../assets/gameList/gameInfo/bjl/btn1.png";
import Btn2Bjl from "../assets/gameList/gameInfo/bjl/btn2.png";
import IntroBjl from "../assets/gameList/gameInfo/bjl/intro.png";

import TitleBcbm from "../assets/gameList/gameInfo/bcbm/title.png";
import Btn1Bcbm from "../assets/gameList/gameInfo/bcbm/btn1.png";
import Btn2Bcbm from "../assets/gameList/gameInfo/bcbm/btn2.png";
import IntroBcbm from "../assets/gameList/gameInfo/bcbm/intro.png";

import TitleCbzb from "../assets/gameList/gameInfo/cbzb/title.png";
import Btn1Cbzb from "../assets/gameList/gameInfo/cbzb/btn1.png";
import Btn2Cbzb from "../assets/gameList/gameInfo/cbzb/btn2.png";
import IntroCbzb from "../assets/gameList/gameInfo/cbzb/intro.png";

import TitleCsd from "../assets/gameList/gameInfo/csd/title.png";
import Btn1Csd from "../assets/gameList/gameInfo/csd/btn1.png";
import Btn2Csd from "../assets/gameList/gameInfo/csd/btn2.png";
import IntroCsd from "../assets/gameList/gameInfo/csd/intro.png";

import TitleCycdx from "../assets/gameList/gameInfo/cycdx/title.png";
import Btn1Cycdx from "../assets/gameList/gameInfo/cycdx/btn1.png";
import Btn2Cycdx from "../assets/gameList/gameInfo/cycdx/btn2.png";
import IntroCycdx from "../assets/gameList/gameInfo/cycdx/intro.png";

import TitleCylhd from "../assets/gameList/gameInfo/cylhd/title.png";
import Btn1Cylhd from "../assets/gameList/gameInfo/cylhd/btn1.png";
import Btn2Cylhd from "../assets/gameList/gameInfo/cylhd/btn2.png";
import IntroCylhd from "../assets/gameList/gameInfo/cylhd/intro.png";

import TitleDzpk from "../assets/gameList/gameInfo/dzpk/title.png";
import Btn1Dzpk from "../assets/gameList/gameInfo/dzpk/btn1.png";
import Btn2Dzpk from "../assets/gameList/gameInfo/dzpk/btn2.png";
import IntroDzpk from "../assets/gameList/gameInfo/dzpk/intro.png";

import Title2rmj from "../assets/gameList/gameInfo/2rmj/title.png";
import Btn12rmj from "../assets/gameList/gameInfo/2rmj/btn1.png";
import Btn22rmj from "../assets/gameList/gameInfo/2rmj/btn2.png";
import Intro2rmj from "../assets/gameList/gameInfo/2rmj/intro.png";

import TitleSzffc from "../assets/gameList/gameInfo/szffc/title.png";
import Btn1Szffc from "../assets/gameList/gameInfo/szffc/btn1.png";
import Btn2Szffc from "../assets/gameList/gameInfo/szffc/btn2.png";
import IntroSzffc from "../assets/gameList/gameInfo/szffc/intro.png";

import TitleHbld from "../assets/gameList/gameInfo/hbld/title.png";
import Btn1Hbld from "../assets/gameList/gameInfo/hbld/btn1.png";
import Btn2Hbld from "../assets/gameList/gameInfo/hbld/btn2.png";
import IntroHbld from "../assets/gameList/gameInfo/hbld/intro.png";

import TitleHhdz from "../assets/gameList/gameInfo/hhdz/title.png";
import Btn1Hhdz from "../assets/gameList/gameInfo/hhdz/btn1.png";
import Btn2Hhdz from "../assets/gameList/gameInfo/hhdz/btn2.png";
import IntroHhdz from "../assets/gameList/gameInfo/hhdz/intro.png";

import TitleHwby from "../assets/gameList/gameInfo/hwby/title.png";
import Btn1Hwby from "../assets/gameList/gameInfo/hwby/btn1.png";
import Btn2Hwby from "../assets/gameList/gameInfo/hwby/btn2.png";
import IntroHwby from "../assets/gameList/gameInfo/hwby/intro.png";

import TitleFctbj from "../assets/gameList/gameInfo/fctbj/title.png";
import Btn1Fctbj from "../assets/gameList/gameInfo/fctbj/btn1.png";
import Btn2Fctbj from "../assets/gameList/gameInfo/fctbj/btn2.png";
import IntroFctbj from "../assets/gameList/gameInfo/fctbj/intro.png";

import TitlePdk from "../assets/gameList/gameInfo/pdk/title.png";
import Btn1Pdk from "../assets/gameList/gameInfo/pdk/btn1.png";
import Btn2Pdk from "../assets/gameList/gameInfo/pdk/btn2.png";
import IntroPdk from "../assets/gameList/gameInfo/pdk/intro.png";

import TitleQznn from "../assets/gameList/gameInfo/qznn/title.png";
import Btn1Qznn from "../assets/gameList/gameInfo/qznn/btn1.png";
import Btn2Qznn from "../assets/gameList/gameInfo/qznn/btn2.png";
import IntroQznn from "../assets/gameList/gameInfo/qznn/intro.png";

import TitleFkxw from "../assets/gameList/gameInfo/fkxw/title.png";
import Btn1Fkxw from "../assets/gameList/gameInfo/fkxw/btn1.png";
import Btn2Fkxw from "../assets/gameList/gameInfo/fkxw/btn2.png";
import IntroFkxw from "../assets/gameList/gameInfo/fkxw/intro.png";

import TitleJbpby from "../assets/gameList/gameInfo/jbpby/title.png";
import Btn1Jbpby from "../assets/gameList/gameInfo/jbpby/btn1.png";
import Btn2Jbpby from "../assets/gameList/gameInfo/jbpby/btn2.png";
import IntroJbpby from "../assets/gameList/gameInfo/jbpby/intro.png";

import TitleLp from "../assets/gameList/gameInfo/lp/title.png";
import Btn1Lp from "../assets/gameList/gameInfo/lp/btn1.png";
import Btn2Lp from "../assets/gameList/gameInfo/lp/btn2.png";
import IntroLp from "../assets/gameList/gameInfo/lp/intro.png";

import TitleLhd from "../assets/gameList/gameInfo/lhd/title.png";
import Btn1Lhd from "../assets/gameList/gameInfo/lhd/btn1.png";
import Btn2Lhd from "../assets/gameList/gameInfo/lhd/btn2.png";
import IntroLhd from "../assets/gameList/gameInfo/lhd/intro.png";

import Title13s from "../assets/gameList/gameInfo/13s/title.png";
import Btn113s from "../assets/gameList/gameInfo/13s/btn1.png";
import Btn213s from "../assets/gameList/gameInfo/13s/btn2.png";
import Intro13s from "../assets/gameList/gameInfo/13s/intro.png";

import TitleSgj from "../assets/gameList/gameInfo/sgj/title.png";
import Btn1Sgj from "../assets/gameList/gameInfo/sgj/btn1.png";
import Btn2Sgj from "../assets/gameList/gameInfo/sgj/btn2.png";
import IntroSgj from "../assets/gameList/gameInfo/sgj/intro.png";

import TitleZjh from "../assets/gameList/gameInfo/zjh/title.png";
import Btn1Zjh from "../assets/gameList/gameInfo/zjh/btn1.png";
import Btn2Zjh from "../assets/gameList/gameInfo/zjh/btn2.png";
import IntroZjh from "../assets/gameList/gameInfo/zjh/intro.png";

import TitleSh from "../assets/gameList/gameInfo/sh/title.png";
import Btn1Sh from "../assets/gameList/gameInfo/sh/btn1.png";
import Btn2Sh from "../assets/gameList/gameInfo/sh/btn2.png";
import IntroSh from "../assets/gameList/gameInfo/sh/intro.png";

import TitleSzwg from "../assets/gameList/gameInfo/szwg/title.png";
import Btn1Szwg from "../assets/gameList/gameInfo/szwg/btn1.png";
import Btn2Szwg from "../assets/gameList/gameInfo/szwg/btn2.png";
import IntroSzwg from "../assets/gameList/gameInfo/szwg/intro.png";

import TitleSb from "../assets/gameList/gameInfo/sb/title.png";
import Btn1Sb from "../assets/gameList/gameInfo/sb/btn1.png";
import Btn2Sb from "../assets/gameList/gameInfo/sb/btn2.png";
import IntroSb from "../assets/gameList/gameInfo/sb/intro.png";

export const gameInfoListMap = [
  {
    name: "二八杠",
    title: Title28g,//抬头
    tittlenew:Titlebg,//新抬头
    sideBarBtn: [Btn128g, Btn228g],//两个按钮底图
    intro: Intro28g,//说明图纸
  },
  {
    name: "二十一点",
    title: Title21d,
    tittlenew:Titlebg,//新抬头
    sideBarBtn: [Btn121d, Btn221d],
    intro: Intro21d,
  },
  {
    name: "斗地主",
    title: TitleDdz,
    tittlenew:Titlebg,//新抬头
    sideBarBtn: [Btn1Ddz, Btn2Ddz],
    intro: IntroDdz,
  },
  // {
  //   name: "多福多彩",
  //   title: TitleDfdc,
  //   sideBarBtn: [Btn1Dfdc, Btn2Dfdc],
  //   intro: IntroDfdc,
  // },
  {
    name: "百人牛牛",
    title: TitleBrnn,
    tittlenew:Titlebg,//新抬头
    sideBarBtn: [Btn1Brnn, Btn2Brnn],
    intro: IntroBrnn,
  },
  {
    name: "百家乐",
    title: TitleBjl,
    tittlenew:Titlebg,//新抬头
    sideBarBtn: [Btn1Bjl, Btn2Bjl],
    intro: IntroBjl,
  },
  {
    name: "奔驰宝马",
    title: TitleBcbm,
    tittlenew:Titlebg,//新抬头
    sideBarBtn: [Btn1Bcbm, Btn2Bcbm],
    intro: IntroBcbm,
  },
  // {
  //   name: "城堡争霸",
  //   title: TitleCbzb,
  //   sideBarBtn: [Btn1Cbzb, Btn2Cbzb],
  //   intro: IntroCbzb,
  // },
  {
    name: "财神到",
    title: TitleCsd,
    tittlenew:Titlebg,//新抬头
    sideBarBtn: [Btn1Csd, Btn2Csd],
    intro: IntroCsd,
  },
  // {
  //   name: "彩源猜大小",
  //   title: TitleCycdx,
  //   sideBarBtn: [Btn1Cycdx, Btn2Cycdx],
  //   intro: IntroCycdx,
  // },
  // {
  //   name: "彩源龙虎斗",
  //   title: TitleCylhd,
  //   sideBarBtn: [Btn1Cylhd, Btn2Cylhd],
  //   intro: IntroCylhd,
  // },
  {
    name: "德州扑克",
    title: TitleDzpk,
    tittlenew:Titlebg,//新抬头
    sideBarBtn: [Btn1Dzpk, Btn2Dzpk],
    intro: IntroDzpk,
  },
  {
    name: "二人麻将",
    title: Title2rmj,
    tittlenew:Titlebg,//新抬头
    sideBarBtn: [Btn12rmj, Btn22rmj],
    intro: Intro2rmj,
  },
  // {
  //   name: "上庄分分彩",
  //   title: TitleSzffc,
  //   sideBarBtn: [Btn1Szffc, Btn2Szffc],
  //   intro: IntroSzffc,
  // },
  // {
  //   name: "红包乱斗",
  //   title: TitleHbld,
  //   sideBarBtn: [Btn1Hbld, Btn2Hbld],
  //   intro: IntroHbld,
  // },
  {
    name: "红黑大战",
    title: TitleHhdz,
    tittlenew:Titlebg,//新抬头
    sideBarBtn: [Btn1Hhdz, Btn2Hhdz],
    intro: IntroHhdz,
  },
  // {
  //   name: "海王捕鱼",
  //   title: TitleHwby,
  //   sideBarBtn: [Btn1Hwby, Btn2Hwby],
  //   intro: IntroHwby,
  // },
  // {
  //   name: "发财推币机",
  //   title: TitleFctbj,
  //   sideBarBtn: [Btn1Fctbj, Btn2Fctbj],
  //   intro: IntroFctbj,
  // },
  {
    name: "跑得快",
    title: TitlePdk,
    tittlenew:Titlebg,//新抬头
    sideBarBtn: [Btn1Pdk, Btn2Pdk],
    intro: IntroPdk,
  },
  {
    name: "抢庄牛牛",
    title: TitleQznn,
    tittlenew:Titlebg,//新抬头
    sideBarBtn: [Btn1Qznn, Btn2Qznn],
    intro: IntroQznn,
  },
  // {
  //   name: "疯狂漩涡",
  //   title: TitleFkxw,
  //   sideBarBtn: [Btn1Fkxw, Btn2Fkxw],
  //   intro: IntroFkxw,
  // },
  // {
  //   name: "聚宝盆捕鱼",
  //   title: TitleJbpby,
  //   sideBarBtn: [Btn1Jbpby, Btn2Jbpby],
  //   intro: IntroJbpby,
  // },
  {
    name: "轮盘",
    title: TitleLp,
    tittlenew:Titlebg,//新抬头
    sideBarBtn: [Btn1Lp, Btn2Lp],
    intro: IntroLp,
  },
  {
    name: "龙虎斗",
    title: TitleLhd,
    tittlenew:Titlebg,//新抬头
    sideBarBtn: [Btn1Lhd, Btn2Lhd],
    intro: IntroLhd,
  },
  {
    name: "十三水",
    title: Title13s,
    tittlenew:Titlebg,//新抬头
    sideBarBtn: [Btn113s, Btn213s],
    intro: Intro13s,
  },
  {
    name: "水果机",
    title: TitleSgj,
    tittlenew:Titlebg,//新抬头
    sideBarBtn: [Btn1Sgj, Btn2Sgj],
    intro: IntroSgj,
  },
  {
    name: "扎金花",
    title: TitleZjh,
    tittlenew:Titlebg,//新抬头
    sideBarBtn: [Btn1Zjh, Btn2Zjh],
    intro: IntroZjh,
  },
  // {
  //   name: "梭哈",
  //   title: TitleSh,
  //   sideBarBtn: [Btn1Sh, Btn2Sh],
  //   intro: IntroSh,
  // },
  {
    name: "狮子王国",
    title: TitleSzwg,
    tittlenew:Titlebg,//新抬头
    sideBarBtn: [Btn1Szwg, Btn2Szwg],
    intro: IntroSzwg,
  },
  {
    name: "骰宝",
    title: TitleSb,
    tittlenew:Titlebg,//新抬头
    sideBarBtn: [Btn1Sb, Btn2Sb],
    intro: IntroSb,
  },
];
