import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useEffect, useRef, useState } from "react";
import "animate.css";
import "animate.css/animate.min.css";
import Skype from "../assets/global/skype.png";
import Telegram from "../assets/global/telegram.png";
import Email from "../assets/global/email.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "./cooperation.scss";
import Modal from './Modal';
//弹窗

import Text8 from "../assets/home/8_text.png";
import Text8_icon from "../assets/home/icon.png";
import GameModal from "../components/GameModal";
import { fetchGameHallLink } from "../utils/publicActions";
const Cooperation = () => {
  const [gameLink, setGameLink] = useState("");
  const [showModal, setShowModal] = useState("");
  // 進入遊戲大廳
  const handleEnterHall = async () => {
    const res = await fetchGameHallLink();
    console.log('res==',res);
    if (res === '403') {
      setShowModal(true)
    } else {
      setGameLink(res);

    }
  };
 
  return (
    <div className="cooperationWrapper">
      <Navbar />
      {showModal && (
        <Modal setShowModal={setShowModal} />
      )
      }
      <button className="button" onClick={handleEnterHall} />
      <div className="bg2"> </div>
      <div className="bg3"> </div>
      <SwiperSlide className="h-full indexSec indexSec8">
        <div className="contentWrapper">
          <div
            className="py-6 h-2/5 max-h-64"
            data-swiper-animation="animate__zoomIn"
            data-duration="2s"
            id="contenttop08"
          >
            <img src={Text8} alt="" className="mx-auto h-full" />
          </div>
          <div className="societyWrapper"
            id="contenttop008">
            <div
              className="flex "
              data-swiper-animation="animate__zoomInUp"
              data-duration="1.3s"
            >
              <img src={Text8_icon} alt="" className="mx-auto pic" />
              {/* <div className="societyItem mt-20">
                  <img src={Skype} alt="" />
                  <p>Skype</p>
                  <p>Bd@odin.club</p>
                </div>
                <div className="societyItem">
                  <img src={Telegram} alt="" />
                  <p>Telegram</p>
                  <p>@Odingroup99</p>
                </div>
                <div className="societyItem mt-20">
                  <img src={Email} alt="" />
                  <p>邮箱</p>
                  <p>Market@odin.club</p>
                </div> */}
            </div>
          </div>
        </div>
        <Footer />
      </SwiperSlide>
      {/* <Footer /> */}
      {gameLink && <GameModal gameLink={gameLink} setGameLink={setGameLink} />}
    </div>
  );
};

export default Cooperation;
