import { getPublicKey, getWebGameLink } from "../api/api";
import JSEncrypt from "jsencrypt";

const encrypt = new JSEncrypt();

// 進入遊戲大廳
export const fetchGameHallLink = async () => {
  // RSA加密用戶帳號密碼
  let encrypted;
  const user = localStorage.getItem("demoAccount");
  console.log('user===', user);
  // 向後端獲取公鑰
  const publicKey = await getPublicKey();
  // 如果公鑰＆用戶帳密均有效，開始進行加密
  if (publicKey && JSON.stringify(publicKey) !== "{}" && user) {
    encrypt.setPublicKey(publicKey);
    encrypted = encrypt.encrypt(user);
    // 使用加密後的帳密撈取試玩網址
    const webGame = await getWebGameLink(encrypted);
    console.log(webGame.code, 'webGame.msg===', webGame.msg);

    if (webGame.code === 200 && webGame.msg) {
      let link = webGame.msg.domain_list[0] + webGame.msg.params;
      return link;
    } else if (webGame.code === 403 || webGame.code === 406) {
      console.log('===============');
      let link = '403'
      return link;
    }
  } else {
    let link = '403'
    return link;
  }
};
