import TryFreeImg from "../assets/global/tryFree.png";
import "./tryFreeButton.scss";

const TryFreeButton = ({ showDropdown, setShowDropdown, handleEnterHall }) => {
  return (
    <button
      className="tryFreeBtn fixed z-20 w-28"
      onClick={() => setShowDropdown(!showDropdown)}
    >
      {/* <img src={TryFreeImg} alt="" /> */}
      {showDropdown && (
        <div className="absolute -left-32 top-1/2 flex flex-col bg-white rounded space-y-3 p-3">
          <button className="tryFreeItem" onClick={handleEnterHall}>
            样式一
          </button>
          <button className="tryFreeItem invalid" disabled>
            样式二
          </button>
          <button className="tryFreeItem invalid" disabled>
            样式三
          </button>
          <button className="tryFreeItem invalid" disabled>
            样式四
          </button>
        </div>
      )}
    </button>
  );
};

export default TryFreeButton;
