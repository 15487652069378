import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import GameList from "./pages/GameList";
import Cooperation from "./pages/Cooperation";
import GameIntro from "./pages/GameIntro";
import { useEffect } from "react";
import cryptoRandomString from "crypto-random-string";

function App() {
  useEffect(() => {
    console.log('localStorage.getItem("demoAccount")==',localStorage.getItem("demoAccount"));
    if (localStorage.getItem("demoAccount")) return;

    const obj = {
      // 產生隨機亂數當作玩家帳號
      account: cryptoRandomString({ length: 9, type: "alphanumeric" }),
      password: "123456",
    };

    localStorage.setItem("demoAccount", JSON.stringify(obj));
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="*" element={<Navigate to="/home" />} />
        <Route exact path="/" element={<Home />} />
        <Route exact path="/gameList" element={<GameList />} />
        <Route exact path="/gameIntro" element={<GameIntro />} />
        <Route exact path="/cooperation" element={<Cooperation />} />
        {/* <Route exact path='/' element={<Navigate to='/home' />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
