import FooterContent from "../assets/global/footer_content.png";
import "./footer.scss";

const Footer = () => {
  return (
    <div className="footerWrapper">
      <img src={FooterContent} alt="" />
    </div>
  );
};

export default Footer;
