import { sendRequest } from "./config";

// plain json轉換成formData
const convertJsonToFormData = (data) => {
  let formData = new FormData();

  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }
  return formData;
};

// 撈取加密用公鑰
export const getPublicKey = async () => {
  // 奥丁渠道
  const params = {
    token: "888888",
    platform_code: "51",
  };
  const res = await sendRequest({
    method: "get",
    url: "/getEncryptionKey",
    params,
    baseURL: process.env.REACT_APP_BASEURL,
  });
  return res;
};

export const getWebGameLink = async (encrypted) => {
  // 奥丁渠道
  const _body = {
    token: "888888",
    platform_code: "51",
    params: encrypted,
    open_back_hall: 1,
  };

  const body = convertJsonToFormData(_body);

  const res = await sendRequest({
    method: "post",
    url: "/access",
    body,
    baseURL: process.env.REACT_APP_BASEURL,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res;
};
