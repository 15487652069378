import { Pagination, A11y, Mousewheel, Navigation } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperAnimation from "@cycjimmy/swiper-animation";
import { useState, useEffect, useRef } from "react";
import "swiper/scss";
import "swiper/scss/pagination";
import "./home.scss";
import "./home.css";
import "animate.css";


import Navbar from "../components/Navbar";
import Content1 from "../assets/home/1_content.png";
import Content001 from "../assets/home/1-shang.png";
import Content100 from "../assets/home/1-xia.png";
import Content1_1 from "../assets/home/1_content-01.png";
import Text2 from "../assets/home/2_text.png";
import Content2 from "../assets/home/2_content.png";
import Text3 from "../assets/home/3_text.png";
import Content3 from "../assets/home/3_content.png";
import Text4 from "../assets/home/4_text.png";
import Content4 from "../assets/home/4_content.png";
import Text5 from "../assets/home/5_text.png";
import Content5 from "../assets/home/5_content.png";
import FooterContent from "../assets/global/footer_content.png";
import Text8 from "../assets/home/8_text.png";
import Text8_icon from "../assets/home/icon.png";
// import Skype from "../assets/global/skype.png";
// import Telegram from "../assets/global/telegram.png";
// import Email from "../assets/global/email.png";
// import Footer from "../components/Footer";
import GameModal from "../components/GameModal";
import { fetchGameHallLink } from "../utils/publicActions";
import TryFreeButton from "../components/TryFreeButton";
import Modal from './Modal';


const Home = () => {
  console.log('0824vresion');
  const swiperAnimation = new SwiperAnimation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showModal, setShowModal] = useState("");
  const [gameLink, setGameLink] = useState("");

  // 進入遊戲大廳
  const handleEnterHall = async () => {
    const res = await fetchGameHallLink();
    if (res === '403') {
      setShowModal(true)
    } else {
      setGameLink(res);

    }
  };
  const [data, setData] = useState([])
  const isMobile = (() => {
    var userAgentInfo = navigator.userAgent;

    var mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];

    var mobile_flag = false;
    console.log(userAgentInfo);
    //根据userAgent判断是否是手机
    for (var v = 0; v < mobileAgents.length; v++) {
      if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
        mobile_flag = true;
        break;
      }
    }
    //  var screen_width = window.screen.width;
    //  var screen_height = window.screen.height;

    //根据屏幕分辨率判断是否是手机
    // if (screen_width > 325 && screen_height < 750) {
    //     mobile_flag = true;
    // }

    return mobile_flag;
  })

  useEffect(() => {
    let mobile = isMobile()
    console.log('isMobile===', isMobile());
    if (mobile) {
      console.log(window.location.href);
      let hrefs = window.location.href + ''
      console.log('ad_web==', hrefs.indexOf('ad_web'));
      if (hrefs.indexOf('ad_web') !== -1) {
        let c = hrefs.split('ad_web')
        let d = c[0] + 'mobile_ad' + c[1]
        window.location.href = d

      } else if (hrefs.indexOf('odin') !== -1) {
        let c = hrefs.split('odin')
        let d = c[0] + 'mobile_ad.odin' + c[1]
        window.location.href = d
      }
      else {
        console.log('href=="http://mobile_ad.odinqpgame.com/"');
        window.location.href = "http://mobile_ad.odinqpgame.com/"
      }
    }
  }, [])



  return (
    <div className="homeContainer ">

      <Navbar />
      {showModal && (
        <Modal setShowModal={setShowModal} />
      )
      }
      <div className="indexSec1" >
        <button className="button" onClick={handleEnterHall}
        />
        <div className="icons" 
        />
      </div>
      <div className="indexSec12" >
      </div>
      <div className="indexSec13" >
      </div>
      <div className="indexSec14" >
      </div>
      <div className="indexSec15" >
      </div>
      <Swiper
        className="h-screen swiper-container"
        // install Swiper modules
        modules={[Pagination, A11y, Mousewheel, Navigation]}
        direction="vertical"
        slidesPerView={1}
        pagination={{ clickable: true }}
        mousewheel={true}
        updateOnWindowResize={true}
        onSlideChange={(swiper) => {
          console.log("slide change");
          swiperAnimation.init(swiper).animate();
        }}
        //控制下一页/上一页的指令
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
      >
        <div className="  indexSec1 indexSec111">
          <div
            // id="contenttop02"
            className="w-4/5 "
            data-swiper-animation="animate__zoomIn"
            data-duration="1.3s"
          >

            {/* <img src={Content1} className="content1" alt="" /> */}

          </div>

          <div
            className="w-4/5"
            // data-swiper-animation="animate__bounceInDown"
            id="contenttop01"
            // data-duration="2s"
            data-swiper-animation="animate__bounceInUp"
            data-duration="2s"
          >
            <img src={Content1_1} className="content01" alt="" />
          </div>

          <div

            id="contenttop100"
            className="swiper-button-next"


          >
            <img src={Content100} className="content100" alt="" />
          </div>
        </div>


        {/* <SwiperSlide className="h-full indexSec indexSec2">
          <div
            className="w-8/12 py-6"
            data-swiper-animation="animate__bounceInUp"
            data-duration="2s"
            id="contenttop002"
          >
            <img src={Text2} id='test2' alt="" />
          </div>
          <div
            className="w-6/12"
            data-swiper-animation="animate__zoomIn"
            data-duration="1.3s"
            id="contenttop02"
          >
            <img src={Content2} className="content02" alt="" />
          </div>
          <div

            id="contentbot100"
            className="swiper-button-prev"


          >
            <img src={Content001} className="content100" alt="" />
          </div>
          <div

            id="contenttop100"
            className="swiper-button-next"
          >
            <img src={Content100} className="content100" alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide className="h-full indexSec indexSec3">
          <div
            className="w-8/12 py-6"
            data-swiper-animation="animate__bounceInUp"
            data-duration="2s"
            id="contenttop03"
          >
            <img src={Text3} alt="" />
          </div>
          <div
            // className="w-12/12"
            data-swiper-animation="animate__bounceInLeft"
            data-duration="1.3s"
            id="contenttop101"
          >
            <img src={Content3} id='content3img' alt="" />
          </div>
          <div
            id="contentbot100"
            className="swiper-button-prev"
          >
            <img src={Content001} className="content100" alt="" />
          </div>
          <div
            id="contentbot100"
            className="swiper-button-prev"

          >
            <img src={Content001} className="content100" alt="" />
          </div>
          <div

            id="contenttop100"
            className="swiper-button-next"
            data-duration="2s"
          >
            <img src={Content100} className="content100" alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide className="h-full indexSec indexSec4">
          <div
            className="w-8/12 py-5"
            data-swiper-animation="animate__zoomIn"
            data-duration="2s"
            id="contenttop004"
          >
            <img src={Text4} alt="" />
          </div>
          <div
            className="w-8/12 py-5 self-end"
            data-swiper-animation="animate__bounceInLeft"
            data-duration="1.3s"
            id="contenttop04"
          >
            <img src={Content4} alt="" />
          </div>
          <div
            id="contentbot100"
            className="swiper-button-prev"
          >
            <img src={Content001} className="content100" alt="" />
          </div>
          <div

            id="contenttop100"
            className="swiper-button-next"
            data-duration="2s"
          >
            <img src={Content100} className="content100" alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide className="h-full indexSec indexSec5">

          <div
            data-swiper-animation="animate__bounceInLeft"
            data-duration="1.3s"
            className="h-2/5"
            id="contenttop05"
          >
            <img className="h-full" src={Content5} alt="" />
          </div>
          <div
            className="w-10/12 py-8"
            data-swiper-animation="animate__bounceInRight"
            data-duration="2s"
            id="contenttop005"
          >
            <img src={Text5} alt="" />
          </div>
          <div
            id="contentbot100"
            className="swiper-button-prev"
          >
            <img src={Content001} className="content100" alt="" />
          </div>
          <div

            id="contenttop100"
            className="swiper-button-next"
            data-duration="2s"
          >
            <img src={Content100} className="content100" alt="" />
          </div>
        </SwiperSlide> */}
        {/* <SwiperSlide className="h-full indexSec indexSec6">
          <div
            className="w-8/12 py-12"
            data-swiper-animation="animate__bounceInUp"
            data-duration="2s"
          >
            <img src={Text6} alt="" />
          </div>
          <div className="logoWrapper w-7/12">
            <div
              className="h-1/2"
              data-swiper-animation="animate__zoomIn"
              data-duration="1.3s"
            >
              <img src={ExampleLogo} alt="" className="mx-auto h-full" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="h-full indexSec indexSec7">
          <div
            className="w-8/12 py-5"
            data-swiper-animation="animate__zoomIn"
            data-duration="2s"
          >
            <img src={Text7} alt="" />
          </div>
          <div
            className="w-6/12 py-5"
            data-swiper-animation="animate__zoomIn"
            data-duration="1.3s"
          >
            <img src={Content7} alt="" />
          </div>
        </SwiperSlide> */}

      </Swiper>
      <TryFreeButton
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
        handleEnterHall={handleEnterHall}
      />
      {gameLink && <GameModal gameLink={gameLink} setGameLink={setGameLink} />}
    </div>
  );
};

export default Home;
