import { NavLink } from "react-router-dom";
import React, { Component } from "react";
import LogoImg from "../assets/global/logo.png";
import "./navbar.scss";

class Navbar extends Component {
  // const Navbar = () => 
  constructor(props) {
    super(props);

    this.state = {
      show: false
    };
  }
  showmin = () => {
    this.setState({
      show: !this.state.show
    })
    // this.state.show = !this.state.show
  }
  closmin = () => {
    this.setState({
      show: false
    })
  }
  render() {
    return (
      <div >
        <nav className="fixed top-0 w-full h-20 z-20 flex justify-between items-center navbarWrapper">
          {/* <div id='btn' onClick={this.showmin}></div> */}
          <div className="logoo"></div>
          <div className=" a-20" id="flexnav" href="/">
            <ul className="flex" id='flexnav'>
              <li id='flexli'>
                <NavLink
                  to="/" 
                  // className="ch__title1"
                  className={({ isActive }) => (isActive ? "selected1" : "ch__title1")}
                  // onClick={this.closmin}
                >
                 {/* <div className="ch__title1"> 111 </div> */}


                </NavLink>
              </li>

              <li id='flexli'>
                <NavLink
                  to="/gameList"
                  className={({ isActive }) => (isActive ? "selected2" : "ch__title2")}
                  // onClick={this.closmin}
                >



                </NavLink>
              </li>

              <li id='flexli'>
                <NavLink
                  to="/gameIntro"
                  className={({ isActive }) => (isActive ? "selected3" : "ch__title3")}
                  // onClick={this.closmin}
                >


                </NavLink>
              </li>

              <li id='flexli'>
                <NavLink
                  to="/cooperation"
                  className={({ isActive }) => (isActive ? "selected4" : "ch__title4")}
                  // onClick={this.closmin}
                >

                </NavLink>
              </li>
            </ul>
            {/* <img className="h-full" src={LogoImg} alt="" /> */}
          </div>
        </nav>

        {this.state.show && <div id='min' >
          <div>
            <div id="hamburger-6" className="hamburger is-active" onClick={this.showmin}>
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>

            <ul className="flex">
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) => (isActive ? "selected" : "")}
                  onClick={this.closmin}
                >
                  <span className="index">01</span>
                  <span className="en__title">ABOUT US</span>
                  <span className="ch__title">    关于奥丁</span>

                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/gameList"
                  className={({ isActive }) => (isActive ? "selected" : "")}
                  onClick={this.closmin}
                >
                  <span className="index">02</span>
                  <span className="en__title">GAME DEMO</span>
                  <span className="ch__title">游戏试玩</span>


                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/gameIntro"
                  className={({ isActive }) => (isActive ? "selected" : "")}
                  onClick={this.closmin}
                >
                  <span className="index">03</span>
                  <span className="en__title">RULES OF GAME</span>
                  <span className="ch__title">玩法介绍</span>

                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/cooperation"
                  className={({ isActive }) => (isActive ? "selected" : "")}
                  onClick={this.closmin}
                >
                  <span class="index">04</span>
                  <span class="en__title">COOPERATION</span>
                  <span class="ch__title">商务合作</span>
                </NavLink>
              </li>
            </ul>
            {/* {/* </div> */}
          </div>


        </div>}
      </div>)

  };
}

export default Navbar;
