import Navbar from "../components/Navbar";
// import Type1Btn from "../assets/gameList/type1.png";//fjhsodi
import Type1Btn from "../assets/gameList/fjhsodi.png";//fjhsodi
import Type1ActiveBtn from "../assets/gameList/fjhsodi.png";
import Type2Btn from "../assets/gameList/fjhsodi.png";
import Type2ActiveBtn from "../assets/gameList/fjhsodi.png";
import Type3Btn from "../assets/gameList/fjhsodi.png";
import Type3ActiveBtn from "../assets/gameList/fjhsodi.png";
import Type4Btn from "../assets/gameList/fjhsodi.png";
import Type4ActiveBtn from "../assets/gameList/fjhsodi.png";
// import Type1ActiveBtn from "../assets/gameList/type1_2.png";
// import Type2Btn from "../assets/gameList/type2.png";
// import Type2ActiveBtn from "../assets/gameList/type2_2.png";
// import Type3Btn from "../assets/gameList/type3.png";
// import Type3ActiveBtn from "../assets/gameList/type3_2.png";
// import Type4Btn from "../assets/gameList/type4.png";
// import Type4ActiveBtn from "../assets/gameList/type4_2.png";

import Type1Hall from "../assets/gameList/type1_hall.png";
import Type2Hall from "../assets/gameList/type2_hall.png";
import Type3Hall from "../assets/gameList/type3_hall.png";
import Type4Hall from "../assets/gameList/type4_hall.png";
import { useMemo, useState, useEffect } from "react";
import Footer from "../components/Footer";
import "./gameList.scss";
import GameModal from "../components/GameModal";
import { fetchGameHallLink } from "../utils/publicActions";
import Modal from './Modal';
// sidebar button list images
const typeButtonList = [
  [Type1Btn, Type1ActiveBtn],
  [Type2Btn, Type2ActiveBtn],

];
const typeButtonList2 = [
  [Type1Btn, Type1ActiveBtn],
  [Type2Btn, Type2ActiveBtn],

];

const GameList = () => {

  //1 2 3 4
  const [type, setType] = useState(1);
  console.log('type==', type);
  const [gameLink, setGameLink] = useState("");
  const [showModal, setShowModal] = useState("");
  console.log('gameLink==', gameLink);

  // 進入遊戲大廳
  const handleEnterHall = async () => {
    console.log('跳转');

    const res = await fetchGameHallLink();
    console.log('res====',res);
    
    if (res === '403') {
      setShowModal(true)
    } else {
      setGameLink(res);

    }
  };

  // 切換不同類型的大廳圖
  const displayTypeHall = useMemo(() => {
    let array = [Type1Hall, Type2Hall, Type3Hall, Type4Hall];
    return (
      <button
        onClick={handleEnterHall}
        // disabled={type !== 1}
        // className={type !== 1 ? "comingSoon" : "playNow"}
        className="playNow"

      >
        <img src={array[type - 1]} alt="" />
      </button>
    );
  }, [type]);

  // 切換不同類型的大廳圖
  const displayTypeHall2 = useMemo(() => {


    return (
      <button
        onClick={handleEnterHall}
        // disabled={type !== 1}
        // className={type !== 1 ? "comingSoon1" : "playNow1"}
        className="playNow1"
      >
      </button>
    );
  }, [type]);

  const [data, setData] = useState([])
  useEffect(() => {
    //相当于生命周期compomentDidmonut


    // eslint-disable-next-line no-unused-expressions
    displayTypeHall2

  }, [])
  return (
    <div className="gameListBg">
      {displayTypeHall2}
      {/* <div className="gameContent1">{displayTypeHall2}</div> */}
      <Navbar />
      {showModal && (
        <Modal setShowModal={setShowModal} />
      )
      }

      <div className="gameListContainer">
        <div className="sideBar">
          {/* 遍历 map 生成按钮 */}
          {typeButtonList.map((item, index) => {
            return (
              //原更换底图写法
              <button onClick={() => setType(index + 1)} key={index}>
                {type === index + 1 ? (
                  <img onClick={handleEnterHall} src={item[1]} alt="" />
                ) : (
                  //原写法<img src={item[0]} alt="" />
                  <img onClick={handleEnterHall} src={item[1]} alt="" />
                )}
              </button>

              // <button onClick={handleEnterHall}>
              //   <img src={item[1]} alt="" />
              // </button>
            );
          })}
        </div>
        <div className="sideBar2">
          {/* 遍历 map 生成按钮 */}
          {typeButtonList2.map((item, index) => {
            return (
              //原更换底图写法
              <button className="btn2" onClick={() => setType(index + 1)} key={index}>
                {type === index + 1 ? (
                  <img onClick={handleEnterHall} src={item[1]} alt="" />
                ) : (
                  //原写法<img src={item[0]} alt="" />
                  <img onClick={handleEnterHall} src={item[1]} alt="" />
                )}
              </button>

              // <button onClick={handleEnterHall}>
              //   <img src={item[1]} alt="" />
              // </button>
            );
          })}
        </div>
        <div className="gameContent">{displayTypeHall}</div>
      </div>
      {/* <Footer /> */}
      {gameLink && <GameModal gameLink={gameLink} setGameLink={setGameLink} />}
    </div>
  );
};

export default GameList;
