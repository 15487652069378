import { gameInfoListMap } from "../const/gameListMap";
import Navbar from "../components/Navbar";


import Footer from "../components/Footer";
import { useEffect, useRef, useState } from "react";
import "./gameIntro.scss";
// import Banner from "../assets/gameList/gameInfo/banner.png";
import GameModal from "../components/GameModal";
import { fetchGameHallLink } from "../utils/publicActions";
import TryFreeButton from "../components/TryFreeButton";
import Modal from './Modal';

const GameIntro = () => {
  const [currentGame, setCurrentGame] = useState(gameInfoListMap[0]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showModal, setShowModal] = useState("");
  const ref = useRef(null);
  const [gameLink, setGameLink] = useState("");
  const [isShow,setisShow] = useState(true)
  const check = ()=>{
    setisShow(!isShow)
  }


  // 進入遊戲大廳
  const handleEnterHall = async () => {
    console.log('进入游戏');
    const res = await fetchGameHallLink();
    if (res === '403') {
      setShowModal(true)
    } else {
      setGameLink(res);

    }
  };
  // 進入遊戲大廳
  const goinfo = async (a) => {

    console.log('进入游戏说明');

    setCurrentGame(a)
    check()
    // console.log('a====', a, 'currentGame===', currentGame);
    // window.location.href = "/GameInfobg";
    // this.ref[my]


  };
  useEffect(() => {
    const executeScroll = () => ref.current.scrollIntoView();
    executeScroll();
  }, [currentGame]);
  //title: Title28g,//抬头
  //sideBarBtn: [Btn128g, Btn228g],//两个按钮底图
  //intro: Intro28g,//说明图纸
  return (
    <div className="gameIntroBg">
      <Navbar />
      {showModal && (
        <Modal setShowModal={setShowModal} />
      )
      }
      <div className="gameIntroBanner" ref={ref}>
        {/* //固定人物底图  暂时屏蔽*/}
        {/* <img className="w-full" src={Banner} alt="" /> */}
        <img className="title" onClick={handleEnterHall}
          src={currentGame.tittlenew} alt="" />
      </div>
      <div className="gameIntroContainer">
        <div className="sideBar"   style={{display:isShow?'block':'none'}} >
          {gameInfoListMap.map((item, index) => {
            return (
              <button onClick={() => goinfo(item)} key={index}>
                {currentGame.name === item.name ? (
                  <img src={item.sideBarBtn[1]} alt="" />
                ) : (
                  <img src={item.sideBarBtn[0]} alt="" />
                )}
              </button>
              //setCurrentGame 方法默认为0时为第一张图片，点击时 更新对应参数
              // <button onClick={() => setCurrentGame(item)} key={index}>
              //   {currentGame.name === item.name ? (
              //     <img src={item.sideBarBtn[1]} alt="" />
              //   ) : (
              //     <img src={item.sideBarBtn[0]} alt="" />
              //   )}
              // </button>
            );
          })}
        </div>
        {/* onClick={() => check()} */}
        <div className="IntroContent" style={{display:isShow?'none':'block'}}  >
        <div className="btnclose" onClick={() => check()}></div>
          <img src={currentGame.intro} alt={currentGame.name} />
         
        </div>
      </div>
      {/* <Footer /> */}
      <TryFreeButton
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
        handleEnterHall={handleEnterHall}
      />
      {gameLink && <GameModal gameLink={gameLink} setGameLink={setGameLink} />}
    </div>
  );
};

export default GameIntro;
